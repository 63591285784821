import * as React from 'react'

import Layout, {Block, LeadingMotto} from '../components/Layout'
import Seo from '../components/seo'

const NotFoundPage = () => (
	<Layout>
		<Seo title='404: Not found' />
		<Block
			color='yellowGreen'
			className='pt-48 md:pt-56 pb-10 2xl:pt-72 2xl:pb-20'
			nestedClassName='p-7'
		>
			<h1 class='text-2xl mb-5 font-serif font-bold'>404: Not Found</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</Block>
	</Layout>
)

export default NotFoundPage
